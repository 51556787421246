import './_Create.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DOC_TEMPLATES } from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { useLocalStorage } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import useWriteEssay from '../../hooks/useWriteEssay'
import ErrorModal from '../../Components/Modals/Error'
import { usePostHog } from 'posthog-js/react'
import useIsPremium from '../../hooks/useIsPremium'
import SteppedEssaySetup from './Steps'

function Setup({
	type,
	docID,
	setSubscriptionModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}) {
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [topicValue, setTopicValue] = useState<string>(documentState?.title ?? '')
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const [submitAttempted, setSubmitAttempted] = useState<boolean>(false)
	const formValid = topicValue !== undefined && topicValue.length !== 0
	const [localUserWriterSelections, setLocalUserWriterSelections] = useLocalStorage(docID, blankUserWriterSelections)
	const defaultWordCount = 150
	const isPremium = useIsPremium()
	const useSteppedSetup = type === DOC_TEMPLATES.Writer

	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const { generateEssay, getSubtopics } = useWriteEssay({
		docID: docID,
		type: type,
		topicValue,
		setTopicValue,
		setLocalUserWriterSelections: (selections) => {
			setLocalUserWriterSelections(selections)
			dispatch(setUserWriterSelections({ docID, userWriterSelections: selections }))
		},
		setErrorModalOpen: setErrorModalOpen,
	})

	useEffect(() => {
		posthog.capture('setup-page-viewed')
	}, [])

	useEffect(() => {
		if (!documentState.setup) {
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: { ...documentState.userWriterSelections, wordCount: defaultWordCount },
				})
			)
		}
	}, [documentState.setup, defaultWordCount])

	useEffect(() => {
		setTopicValue(documentState?.title ?? '')
	}, [documentState?.title])

	if (!!documentState.setup || !type) {
		return null
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<SteppedEssaySetup type={type} setErrorModalOpen={setErrorModalOpen} />
		</>
	)
}

export default Setup
