import { useMemo } from 'react'

const TitleQuality = ({ topic }: { topic: string }) => {
	const wordCount = useMemo(() => {
		return topic?.split(' ').filter((word) => word.length > 0).length
	}, [topic])

	return (
		<div className="flex flex-col gap-2">
			<div className={`h-1 border border-gray-300 rounded bg-gray-300`}>
				<div
					className={`h-full rounded border-r transition-all ${
						wordCount < 10 ? (wordCount < 5 ? 'bg-danger-default' : 'bg-warning-default') : 'bg-success-default'
					}`}
					style={{ width: `${((wordCount > 10 ? 10 : wordCount) / 10) * 100}%` }}
				/>
			</div>
			{wordCount > 0 && (
				<div className="text-sm text-gray-700">
					{wordCount < 5 ? (
						<>
							<span className="font-semibold">Weak Prompt:</span> Add more context for better generations
						</>
					) : wordCount < 10 ? (
						<>
							<span className="font-semibold">Fair Prompt:</span> Add more context for better generations
						</>
					) : (
						<>
							<span className="font-semibold">Strong Prompt:</span> Ready for generation
						</>
					)}
				</div>
			)}
		</div>
	)
}

export default TitleQuality
